<template>
  <fragment>
    <section class="uk-section-default uk-section">
      <div class="uk-container uk-container-large">
        <div
          class="uk-grid-margin uk-grid"
          uk-grid
        >
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-visible@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-text-small">
                <a href="tel:+19207073585">+1 920-707-3585</a><br>
                <a href="mailto:info@adventure81marco.com">info@adventure81marco.com</a>
              </div>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-margin uk-text-center">
                <div
                  class="uk-child-width-auto uk-grid-small uk-flex-center uk-grid socialMedia"
                  uk-grid
                >
                  <div class="uk-first-column">
                    <a
                      class="uk-icon"
                      target="_blank"
                      rel="nofollow noopener"
                      href="https://www.facebook.com/Adventure81Marco/"
                    ><i class="fab fa-facebook-f" /></a>
                  </div>
                  <div>
                    <a
                      class="uk-icon"
                      target="_blank"
                      rel="nofollow noopener"
                      href="https://www.instagram.com/adventure81marco/"
                    ><i class="fab fa-instagram" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m uk-visible@m">
            <div class="uk-panel uk-width-1-1">
              <div class="uk-text-right uk-text-small">
                © 2020 Adventure 81 Marco
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    //
  },
  methods: {}
};
</script>